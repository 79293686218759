jQuery(document).ready(function() {
    jQuery(".validated-form").submit(function(e){
        jQuery('input.form-control').removeClass('error');

        var vysledek = true;
        var hlaska = "";
        var hodnota= "";
        jQuery('#success_vypis').addClass('hidden');
        jQuery( ".validated-form .required" ).each(function( index, polozka ) {
            if(hlaska == "" && jQuery(polozka).val()==''){
                jQuery(polozka).addClass('error');
                hlaska = 'Please fill in the '+jQuery(polozka).attr('data-error');
                hodnota   = jQuery(polozka).attr('data-error');
                jQuery(polozka).focus();
                vysledek =  false;
            }
            else if(hlaska == "" && (jQuery(polozka).hasClass('required-email') && !IsEmail(jQuery(polozka).val()) ) ){
                jQuery(polozka).addClass('error');
                hlaska = 'The '+jQuery(polozka).attr('data-error') + ' you entered is not valid.';
                hodnota   = hlaska;
                jQuery(polozka).focus();
                vysledek =  false;
            }

            else if(hlaska == "" && (jQuery(polozka).hasClass('required-phone') && !IsPhone(jQuery(polozka).val()) ) ){
                jQuery(polozka).addClass('error');
                hlaska = 'The '+jQuery(polozka).attr('data-error') + ' you entered is not valid.';
                hodnota   = hlaska;
                jQuery(polozka).focus();
                vysledek =  false;
            }

        });

        if(!vysledek){
            if(!hodnota){ 		jQuery('#error_vypis').removeClass('no-display'); }
            else {
                jQuery('#error_vypis').html('<i class="fa fa-times-circle"></i>&nbsp;'+hlaska).removeClass('no-display');
            }

            return false;
        }
        else{
            jQuery('#error_vypis').addClass('hidden');
            ga('send', 'event', {
                'eventCategory': 'Form',
                'eventAction': 'Successfuly sent'
            });
            return true;
        }
    });


    jQuery(".validated-form-newslatter").submit(function(e){
        jQuery('input.form-control').removeClass('error');

        var vysledek = true;
        var hlaska = "";
        var hodnota= "";
        jQuery('#success_vypis_nws').addClass('hidden');
        jQuery( ".validated-form-newslatter .required" ).each(function( index, polozka ) {
            if(hlaska == "" && jQuery(polozka).val()==''){
                jQuery(polozka).addClass('error');
                hlaska = 'Please fill in the '+jQuery(polozka).attr('data-error');
                hodnota   = jQuery(polozka).attr('data-error');
                jQuery(polozka).focus();
                vysledek =  false;
            }
            else if(hlaska == "" && (jQuery(polozka).hasClass('required-email') && !IsEmail(jQuery(polozka).val()) ) ){
                jQuery(polozka).addClass('error');
                hlaska = 'The '+jQuery(polozka).attr('data-error') + ' you entered is not valid.';
                hodnota   = hlaska;
                jQuery(polozka).focus();
                vysledek =  false;
            }


        });

        if(!vysledek){

            if(!hodnota){ 		jQuery('#error_vypis_nws').removeClass('no-display');  }
            else {
                jQuery('#error_vypis_nws').html('<i class="fa fa-times-circle"></i>&nbsp;'+hlaska).removeClass('no-display');
            }

            return false;
        }
        else{
            jQuery('#error_vypis_nws').addClass('hidden');
            ga('send', 'event', {
                'eventCategory': 'Newslatter',
                'eventAction': 'Successfuly sent newslatter'
            });
            return true;
        }


    });



})


function IsEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}


function checkAvailability(db,col,data,id){
    var vysl="";
    $.ajax({
        type: "POST",
        async: false,
        url: "check_url.php",
        data: { db: db,col:col,data: data,id:id },

        success: function (result) {
            vysl = result;
        }
    });
    return (vysl);
}

function IsPhone(email) {
    var regex = /^(\+)? ?[0-9]{3} ?[0-9]{3} ?[0-9]{3} ?[0-9]{3}$/;
    return regex.test(email);
}





/**
 * Created by Martin on 27.05.2016.
 */
